import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth/services/auth.service';
import { UserService } from './service/user.service';
import { debounceTime } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './widgets/components/confirmation-dialog/confirmation-dialog.component';
import { DOCUMENT } from '@angular/common';
import { PushNotoficationService } from './service/push-notofication.service';
import { Router } from "@angular/router";
import { DefaultService } from './service/default.service';
import { User } from './models/User';
import { AuthSelector } from './store/selector';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { AuthActions } from './store/action';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {
	isLoggedIn$: Observable<boolean>;
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
    user: User = null;
	message;
	private socket: any;
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private authService: AuthService,
		private userService: UserService,
		public defaultService: DefaultService,
		private messagingService: PushNotoficationService,
		public dialog: MatDialog,
        public router: Router,
		private swUpdate: SwUpdate,
		private store: Store<{}>
	) {
		this.socket = io(environment.SOCKET_URL);
    }
	ngOnInit() {
		console.log('navigator.onLine', navigator.onLine)
		this.isLoggedIn$ = this.authService.authenticationState;
		this.isLoggedIn$.pipe(debounceTime(500)).subscribe((data: any) => {
			if (data === true) {
				this.settings();
				this.userObservable.subscribe(data => this.user = data);
				this.messagingService.requestPermission(this.user.id);
				this.messagingService.receiveMessage();
				this.message = this.messagingService.currentMessage;
				this.socket.on('message', response => {
					let data = JSON.parse(response);
					console.log('notification notification notification', data);
					//console.log('data.recipients[this.user.id]', data.recipients[this.user.id]);
					if((data.type === 'message') && (data.recipients[this.user.id])) {
						this.userService.profile("GET", null, {username: this.user.username}).subscribe((data: any) => {
							this.store.dispatch(new AuthActions.loginSuccess({ user: data }));
						});
					}
					if((data.type === 'notification') && (data.recipients[this.user.id]) && (data.sender_id !== this.user.id)) {
						this.userService.profile("GET", null, {username: this.user.username}).subscribe((data: any) => {
							this.store.dispatch(new AuthActions.loginSuccess({ user: data }));
						});
					}
				});
			}
		});

		if (this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(() => {
				window.location.reload();
				/*const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
					width: '350px',
					data: "New version available. Load New Version?"
				});
				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						window.location.reload();
					}
				});*/
			});
		}
		this.userService.userSetting$.subscribe(data => {
			const set = JSON.parse(localStorage.getItem('userSettings'));
			set.forEach(ele => {
				if(ele.group_id === 8) {
					this.document.body.classList.remove('theme-'+ele.value);
					this.document.body.classList.add('theme-'+data);
				}
			});
		});
	}

	settings() {
		this.userService.settings("GET", null, {'per-page': 50}).subscribe(data => {
			const items = data.items;
			localStorage.setItem('userSettings', JSON.stringify(items));
			items.forEach(el => {
				if(el.group_id === 8) {
					this.document.body.classList.add('theme-'+el.value);
				}
			});
		});
	}

	removePushNotification() {
		this.messagingService.removeMessage();
	}

	send() {
		this.messagingService.sendAction();
	}
}

import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { Observable } from 'rxjs';
import { AuthSelector } from 'src/app/store/selector';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-timeline-menu',
	templateUrl: './timeline-menu.component.html',
	styleUrls: ['./timeline-menu.component.scss']
})
export class TimelineMenuComponent implements OnInit {
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
    user: User = null;
	showMenu: boolean = false;
	
	constructor(
		private store: Store<{}>
	) { }

	ngOnInit() {
		this.userObservable.subscribe(data => this.user = data);
		if((this.user.id === 13) || (this.user.id === 31) || (this.user.id === 14) || (this.user.id === 4667) || (this.user.id === 98)) {
			this.showMenu = true;
		}
	}

}

<div class="timeline-menu">
	<mat-tab-group class="w-100 float-left mb-3 bg-white">
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/user/timeline', user.username]" [routerLinkActive]="['active']">Home</a>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/timeline']" [routerLinkActive]="['active']">Timeline</a>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/user/about', user.username]" [routerLinkActive]="['active']">About</a>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/user/friends', user.username]" [routerLinkActive]="['active']">Friends <span
						*ngIf="user">({{user.friends_count}})</span></a>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/photos//my-photo', user.username]" [routerLinkActive]="['active']">Photos</a>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/groups/list', user.username]" [routerLinkActive]="['active']">Groups</a>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/events/list', user.username]" [routerLinkActive]="['active']">Events</a>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<a [routerLink]="['/pages/list', user.username]" [routerLinkActive]="['active']">Pages</a>
			</ng-template>
		</mat-tab>
		<mat-tab *ngIf="showMenu">
			<ng-template mat-tab-label>
				<a [routerLink]="['/user/videos', user.username]" [routerLinkActive]="['active']">Videos</a>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
</div>
<header id="landing-header" [ngClass]="(isLoggedIn$ | async) ? 'fixed' : 'notfixed'">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-2 col-md-3 col-12">
				<a routerLink="/" class="logo" *ngIf="!(isLoggedIn$ | async)">
					<img src="/assets/img/logo.png" alt="" class="img-fluid">
				</a>
				<a routerLink="/user/explore" class="logo" *ngIf="(isLoggedIn$ | async)">
					<img src="/assets/img/logo.png" alt="" class="img-fluid">
				</a>
			</div>
			<div class="col-lg-10 col-md-9 col-12 d-flex justify-content-end align-items-center">
				<div class="search mx-auto col" *ngIf="(isLoggedIn$ | async)" [ngClass]="statusClass ? 'show' : 'notshow'">
					<form novalidate (ngSubmit)="mainSearch(form)" [formGroup]="form" autocomplete="off">
						<div class="input-group w-100">
							<input type="text" #name class="form-control" formControlName="search" placeholder="Search for People...">
							<div class="input-group-append">
								<button class="btn"><i class="fas fa-search"></i></button>
							</div>
						</div>
					</form>
				</div>
				<ul class="navbar-menu d-flex align-items-center" *ngIf="(isLoggedIn$ | async)">
					<li class="nav-item mobile-search">
						<a href="javascript:void(0)" class="nav-link" (click)="searchShow()">
							<!-- <img src="/assets/img/head-icon-4.png" alt=""> -->
							<i class="fas fa-search"></i>
						</a>
					</li>
					<li class="nav-item" *ngIf="user">
						<a href="https://www.paypal.me/ChatDit" class="nav-link" target="_blank" matTooltip="Contribute to ChatDit" matTooltipClass="example-tooltip-red" aria-label="Contribute to ChatDit">
							<i class="fas fa-dollar-sign"></i>
						</a>
					</li>
					<li class="nav-item" *ngIf="user">
						<a routerLink="/user/timeline/{{user.username}}" class="nav-link">
							<!-- <img src="/assets/img/head-icon-4.png" alt=""> -->
							<i class="fas fa-home"></i>
						</a>
					</li>
					<li class="nav-item dropdown-notification" ngbDropdown autoClose="outside">
						<app-notification></app-notification>
					</li>
					<li class="nav-item dropdown big-dropdown">
						<a routerLink="/messanger" class="nav-link" (click)="updateCount()">
							<!-- <img src="/assets/img/head-icon-2.png" alt="">  -->
							<i class="fas fa-envelope"></i>
							<span class="badge badge-danger" *ngIf="(msgCount > 0)">{{ msgCount }}</span></a>
					</li>

					<li class="nav-item dropdown-notification" ngbDropdown autoClose="outside">
						<app-friend-request></app-friend-request>
					</li>
					<li class="nav-item profile" *ngIf="user">
						<a href="javascript:void(0);" class="nav-link dropdown-toggle"
							[matMenuTriggerFor]="profileMenu">
							<img src="{{ user.avatar }}" class="img-fluid rounded-circle" alt="" width="40" height="40">
							<span>{{ user.userProfile.first_name }}</span></a>
						<mat-menu #profileMenu="matMenu" [overlapTrigger]="false" class="header-dropdown">
							<a mat-menu-item routerLink="/user/timeline/{{user.username}}">Profile</a>
							<a mat-menu-item routerLink="/user/setting">Account Setting</a>
							<a mat-menu-item routerLink="/user/update-profile">Update Profile</a>
							<a mat-menu-item routerLink="/pages">Pages</a>
							<a mat-menu-item routerLink="/groups">Groups</a>
							<a mat-menu-item routerLink="/events">Events</a>
							<a mat-menu-item routerLink="/timeline/support">Support Tickets</a>
							<a mat-menu-item (click)="logout()">Logout</a>
						</mat-menu>
					</li>
				</ul>
				<app-login *ngIf="!(isLoggedIn$ | async)"></app-login>
				<div class="right-logo ml-3">
					<a routerLink="/" class="logo py-0" *ngIf="!(isLoggedIn$ | async)">
						<img src="/assets/img/chatdit-logo.png" alt="" class="img-fluid" width="65">
					</a>
					<a routerLink="/user/explore" class="logo py-0" *ngIf="(isLoggedIn$ | async)">
						<img src="/assets/img/chatdit-logo.png" alt="" class="img-fluid" width="65">
					</a>
				</div>
			</div>
		</div>
	</div>
</header> <!-- Header end -->

<!-- <iframe id="myFrame" src="/timeline/post-view/111915" style="height:380px;width:100%"></iframe>
<p>Click the "Tryit" button to hide the first H1 element in the iframe (another document).</p> -->

<!-- <button (click)="myFunction()">Try it</button> -->

<div class="timeline-post card" id="timeline-post">
	<div class="card-header d-flex">
		<div class="profile">
			<ng-container *ngIf="item?.item_owner === item?.user_id">
				<ng-container *ngIf="(moduleTag === 'Page') else postProfile">
					<img [src]="item.item_avatar === false ? '/assets/img/default-90x90.png' : item.item_avatar" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40">
				</ng-container>
				<ng-template #postProfile>
					<img src="{{ item?.owner?.avatar }}" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40">
				</ng-template>
			</ng-container>
			<ng-container *ngIf="item?.item_owner !== item?.user_id">
				<img src="{{ item?.owner?.avatar }}" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40">
			</ng-container>
		</div>
		<div class="caption d-flex align-items-start">
			<div class="handing col px-0">
				<h5 class="text-truncate">
					<!-- {{ moduleTag | json }} -->
					<!-- <ng-container *ngIf="item.item_owner === item.user_id"> -->
					<ng-container *ngIf="(moduleTag === 'UserEvent') || (moduleTag === 'Group') || (moduleTag === 'Page')">
						<a *ngIf="(moduleTag === 'UserEvent')" [routerLink]="['/events/view/', item.item_id, 'timeline']"> {{ item.item_name }} </a>
						<a *ngIf="(moduleTag === 'Group')" [routerLink]="['/groups/view/', item.item_id, 'timeline']"> {{ item.item_name }} </a>
						<a *ngIf="(moduleTag === 'Page')" [routerLink]="['/pages/view/', item.item_id, 'timeline']"> {{ item.item_name }} </a> >  
					</ng-container>
					<ng-container>
						<a routerLink="/user/friend/timeline/{{ item.owner.username }}">{{ item.owner.name }} </a>
					</ng-container>
					<!-- {{moduleTag}} -->
					<span *ngIf="(templateModule === 'Album')">
						Create Album
					</span>
					<span *ngIf="(item.parent)">ReChat a
						<ng-container *ngIf="(templateModule === 'UserComment') else postShare">comment </ng-container>
						<ng-template #postShare> post</ng-template>.
					</span>
					<ng-container *ngIf="item.tagFriends?.length > 0">
						<span> is with </span>
                        <span class="tag-friends">
                            <span *ngFor="let tag of item.tagFriends; let index = index; let last = last">
                                <span *ngIf="last && item.tagFriends.length > 1"> and </span>
                                <!-- <a routerLink="/user/friend/timeline/12">ggg </a> -->
                                <a routerLink="/user/friend/timeline/{{ tag?.user?.username }}">{{ tag?.user?.name }} </a>
                            </span>
                        </span>
					</ng-container>
					<span *ngIf="(item.location)"> in {{ item.location }} </span>
					<span *ngIf="item.key === 'profile_avatar'">Updated profile photo</span>
					<span *ngIf="item.key === 'profile_cover'">Updated cover photo</span>
					<span *ngIf="item.key === 'profile_photos'">posted a message</span>
					<!-- <span *ngIf="(moduleTag === 'UserEvent') && item.item_owner === item.user_id"> <i class="fas fa-angle-right"></i> <a [routerLink]="['/events/view/', item.item_id, 'timeline']"> {{ item.item_name }} </a> </span>
					<span *ngIf="(moduleTag === 'Group') && item.item_owner === item.user_id"> <i class="fas fa-angle-right"></i> <a [routerLink]="['/groups/view/', item.item_id, 'timeline']"> {{ item.item_name }} </a> </span>
					<span *ngIf="(moduleTag === 'Page') && item.item_owner === item.user_id"> <i class="fas fa-angle-right"></i> <a [routerLink]="['/pages/view/', item.item_id, 'timeline']"> {{ item.item_name }}</a> </span> -->
					<i *ngIf="pinPost" class="fas fa-thumbtack pin-post text-primary"></i>
				</h5>
				<div class="time d-flex align-items-center">
					<time>{{ item.created_at * 1000 | date:'MMM d, yy, h:mm a'}}</time>
					<div style="position: relative;">
						<button *ngIf="item.visibility == 1" matTooltip="Public" [matTooltipPosition]="'right'" matTooltipClass="tootip-class" class="btn btn-link"><i class="fas fa-globe-europe"></i></button>
						<button *ngIf="item.visibility == 2" matTooltip="Private / Only Me" [matTooltipPosition]="'right'" matTooltipClass="tootip-class" class="btn btn-link"><i class="fas fa-lock"></i></button>
						<button *ngIf="item.visibility == 3" matTooltip="Friends" [matTooltipPosition]="'right'" matTooltipClass="tootip-class" class="btn btn-link"><i class="fas fa-user-friends"></i></button>
						<button *ngIf="item.visibility == 4" matTooltip="Friend Expect" [matTooltipPosition]="'right'" matTooltipClass="tootip-class" class="btn btn-link"><i class="fas fa-user-slash"></i></button>
						<button *ngIf="item.visibility == 5" matTooltip="Friend Specific" [matTooltipPosition]="'right'" matTooltipClass="tootip-class" class="btn btn-link"><i class="fas fa-user"></i></button>
					</div>
					<time><i class="fa fa-eye"></i> {{gs.shortNumber(item.views_count)}}</time>
					<!-- <div ngbDropdown *ngIf="isOwner(userProfile.id)">
						<button class="btn btn-link" ngbDropdownToggle><i class="fas fa-globe-europe"></i></button>
						<div ngbDropdownMenu>
							<a class="dropdown-item" mat-button color="primary" *ngFor="let vs of item.itemVisibility;" (click)="setVisibility(vs.visibility)">{{vs.key|titlecase}}</a>
						</div>
					</div> -->
				</div>
			</div>
			<div class="button" ngbDropdown >
				<button class="btn btn-link" ngbDropdownToggle><i class="fas fa-chevron-down"></i></button>
				<div ngbDropdownMenu>
					<a class="dropdown-item" mat-button color="primary" routerLink="/timeline/post-view/{{item.id}}">View Post</a>
					<ng-container *ngIf="(templateModule == 'UserComment') else postOption">
						<a *ngIf="isOwner(userProfile.id)" class="dropdown-item" mat-button color="primary" (click)="deletePost()">Delete this post from page</a>
						<a class="dropdown-item" mat-button color="primary" (click)="hidePost('hide', 'hided')">Hide this post from your timeline</a>
					</ng-container>
					<ng-template #postOption>
						<ng-container *ngIf="!isOwner(userProfile.id)">
							<a class="dropdown-item" mat-button color="primary" *ngIf="(item.isSaved == 0)" (click)="savePost()">Save Post</a>
							<a class="dropdown-item" mat-button color="primary" *ngIf="(item.isSaved == 1)" (click)="unsavePost()">Unsave Post</a>
						</ng-container>
						<a *ngIf="(isOwner(userProfile.id) && userTimelinePin)" class="dropdown-item" mat-button color="primary" (click)="pinToTop()"> {{pinPost ? 'Remove from Top of Page' : 'Pin to Top of page'}}</a>
						<a *ngIf="isOwner(userProfile.id)" class="dropdown-item" mat-button color="primary" routerLink="/timeline/post/update/{{item.id}}">Edit this Post</a>
						<a class="dropdown-item" mat-button color="primary" (click)="copyToClipboard()">Copy link to this Post</a>
						<a *ngIf="isOwner(userProfile.id)" class="dropdown-item" mat-button color="primary" (click)="deletePost('Do you want to Delete this post from this page?')">Delete this post from page</a>
						<a class="dropdown-item" mat-button color="primary" (click)="embedPost()">Embed this Post</a>
						<ng-container *ngIf="!isOwner(userProfile.id)">
							<!--<a class="dropdown-item" mat-button color="primary" (click)="deletePost('Do you want to Hide this specific post?')">Hide This Specific Post</a>-->
							
							<a class="dropdown-item" mat-button color="primary" *ngIf="hiddenPost === null && myTimeline" (click)="hidePost('hide', 'hided')">Hide This Specific Post</a>
							<a class="dropdown-item" mat-button color="primary" *ngIf="hiddenPost !== null" (click)="hidePost('unhide', 'hided')">Unhide This Specific Post</a>
							<a *ngIf="myTimeline" class="dropdown-item" mat-button color="primary" (click)="hideAllPostsThisUser(item)">Hide All Posts From This User</a>
							<a *ngIf="(myTimeline) && (item.owner.friendState === 1)" class="dropdown-item" mat-button color="primary" (click)="blockUser('permanently')">Block This User Permanently</a>
							<a *ngIf="(myTimeline) && (item.owner.friendState === 5)" class="dropdown-item" mat-button color="primary" (click)="blockUser('permanently')">Unblock This User Permanently</a>
						</ng-container>
						<a *ngIf="isOwner(userProfile.id)" class="dropdown-item" mat-button color="primary" href="/timeline/post-view/{{item.id}}" target="_blank">Show in a new Web Browser</a>
						<a *ngIf="isOwner(userProfile.id) && (hiddenPost === null)" class="dropdown-item" mat-button color="primary" (click)="hidePost('hide', 'hided')"> Hide this post from your timeline</a>
						<a *ngIf="isOwner(userProfile.id) && (hiddenPost !== null) && (hiddenMyPost === null)" class="dropdown-item" mat-button color="primary" (click)="hidePost('unhide', 'hided')"> Unhide this post from your timeline</a>

						<a *ngIf="isOwner(userProfile.id) && (hiddenPost === null)" class="dropdown-item" mat-button color="primary" (click)="hidePost('hide', 'hided-my-post')"> Hide my post from all friends timeline</a>
						<a *ngIf="isOwner(userProfile.id) && (hiddenMyPost !== null)" class="dropdown-item" mat-button color="primary" (click)="hidePost('unhide', 'hided-my-post')"> Unhide my post from all friends timeline</a>

						<a class="dropdown-item" mat-button color="primary" (click)="turnOnNotification()">Turn on notifications for this post</a>
						<a *ngIf="isOwner(userProfile.id)" class="dropdown-item" mat-button color="primary" (click)="showAnalytics()">Show Analytics</a>
						<a *ngIf="isOwner(userProfile.id)" class="dropdown-item" mat-button color="primary" (click)="blockUserList()">Block user comment list this post</a>
						<ng-container *ngIf="!isOwner(userProfile.id)">
							<a *ngIf="(myTimeline) && (item.owner.friendState === 1)" class="dropdown-item" mat-button color="primary" (click)="blockUser('temporary')">Take a 30 day break from seeing <br> posts from this user</a>
							<a *ngIf="(myTimeline) && (item.owner.friendState === 4)" class="dropdown-item" mat-button color="primary" (click)="blockUser('temporary')">Take a 30 day unbreak from seeing <br> posts from this user</a>
							<!-- <a class="dropdown-item" mat-button color="primary" (click)="userBreak(item)">Block user</a> -->
							<a class="dropdown-item" mat-button color="primary" (click)="reportAbuse()">Report this Post / Page</a>
							<a *ngIf="(item.owner.is_follow === false)" class="dropdown-item" mat-button color="primary" (click)="followUser(item, 'follow')">Follow {{ item.owner.name }}</a>
							<a *ngIf="(item.owner.is_follow === true)" class="dropdown-item" mat-button color="primary" (click)="followUser(item, 'unfollow')">Unfollow {{ item.owner.name }}</a>
						</ng-container>
					</ng-template>
					<!-- <a *ngIf="isOwner(userProfile.id)" class="dropdown-item" mat-button color="primary" (click)="showAnalytics()">Show Analytics</a> -->
				</div>
			</div>
		</div>
	</div>

	<ng-container *ngIf="(templateModule === 'UserComment') else postContents">
		<div class="card-body" *ngIf="item.content">
			<div class="card-text">
				<div class="w-100">
					<a routerLink="/timeline/post-view/{{ item.parent_id }}">{{item.content}}</a>
				</div>
				<div class="w-100 text-right">
					<a routerLink="/timeline/post-view/{{ item.parent_id }}"><small>View Original Post</small></a>
				</div>
				<!-- /timeline/post-view/{{item.parent.id}} -->
			</div>
		</div>
	</ng-container>

	<ng-template #postContents>

		<div class="group-image" *ngIf="item.medias.length > 0">
			<div class="d-flex autopay-videos flex-wrap img-count-{{item.medias.length}}">
				<!-- <pre>{{item.imageUrl | json}}</pre> -->
				<div *ngFor="let image of item.medias; let i = index" class="w-50 open-image first-video" [ngClass]="{'hide': i > 3}" (click)="openImage(image.id, item.medias)">
					<ng-container *ngIf="(image?.file?.type === 'image')">
						<!-- <img src="{{image.photo.file.thumb}}" alt="" class="img-fluid w-100"> -->
						<img-lazy src="{{ image?.file?.thumb }}" class="img-fluid w-100" [class]="'full-image'"></img-lazy>
					</ng-container>
					<ng-container *ngIf="(image?.file?.type === 'video')">
						<div class="videos autopay-videos">
							<i class="fas fa-video"></i>
							<video name="media" [muted]="true" controls playsinline loop preload="auto">
								<source src="{{image?.file?.videoUrl}}" type="{{image?.file?.mimetype}}">
							</video>
						</div>
					</ng-container>
					<div *ngIf="(i === 3 && item.medias.length > 4)" class="more-images d-flex align-items-center justify-content-between">
						<span class="w-100 text-center">+ {{ item.medias.length - 4 }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="card-body" *ngIf="(item.content) || (item.postVideos)">
			<div class="card-text">
				<ng-container *ngIf="item.postVideos">
					<div *ngIf="(item.postVideos?.is_live === 0) && (item.postVideos?.schedule !== 0)">
						<div class="posted-date">
							<div class="d-flex mb-3 align-items-center justify-content-between">
								<div>
									<i class="fas fa-video text-primary"></i> {{ item.postVideos.schedule * 1000 | date:'medium'}}
								</div>
								<div>
									<a [routerLink]="['//timeline/post-view', item.id]" class="btn btn-outline-primary">Go To Live</a>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="(item.postVideos?.is_live === 1) && (item.postVideos?.schedule === 0)" class="live-stream-video">
						<!-- <app-live-video-play [postVideos]="item.postVideos"></app-live-video-play> -->
						<!-- [innerHtml]="gs.checkLiveStream(item.postVideos) | safewidget: 'html'" -->
						<!-- {{ item.postVideos.url | json }} -->
						<!-- <iframe id="postVideos_live" src="{{item.postVideos.url}}" class="w-100" width="640" height="300" frameborder="0" fullscreen allowfullscreen></iframe> -->
						<div [innerHtml]="gs.checkLiveStream(item.postVideos) | safewidget: 'html'"></div>
					</div>
					<div *ngIf="(item.postVideos?.is_live === 0) && (item.postVideos?.schedule === 0)" class="live-stream-video autopay-videos">
						<div class="live-video-play first-video">
							<div class="videos">
								<video name="media" [muted]="true" controls class="w-100">
									<source [src]="StreamVideo" type="video/mp4">
								</video>
							</div>
						</div>
					</div>
				</ng-container>
				<!-- <app-live-video-play [postVideos]="item.postVideos"></app-live-video-play> -->

				<read-more [text]="item.content" [maxLength]="250"></read-more>
				<ng-container *ngIf="gs.checkVideoUrl(item.content)">
					<div class="post-video-priview" [innerHtml]="gs.checkVideoUrl(item.content) | safewidget: 'html'"></div>
				</ng-container>
				<ng-container *ngIf="(item.linkPreview.length > 0 && !gs.checkVideoUrl(item.content))">
					<div class="link-priview d-flex flex-wrap" *ngFor="let preview of item.linkPreview | slice:0:1">
						<div class="img w-100 text-center my-3 border-left border-right">
							<a href="{{preview.url}}" target="_blank">
								<img src="{{ (preview.imageUrl === 'no-avatar.png') ? '/assets/no-avatar.png' : preview.imageUrl }}" class="img-fluid" alt="{{preview.title}}"/>
							</a>
						</div>
						<div class="caption px-2 w-100">
							<h5 class="mt-0">
								<a href="{{preview.url}}" class="targer-link" target="_blank">{{preview.title}}</a>
							</h5>
							<p class="mb-0">{{preview.description}}</p>
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<!-- {{ stremUrl | safe: 'html' }} -->

		<div class="share-post border-top" *ngIf="(item.parent)">
			<div class="card-header d-flex">
				<div class="profile">
					<ng-container *ngIf="item?.parent?.item_owner === item?.parent?.user_id">
						<ng-container *ngIf="(moduleTag === 'Page') else postProfile">
							<img [src]="item.parent.item_avatar === false ? '/assets/img/default-90x90.png' : item.parent.item_avatar" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40">
						</ng-container>
						<ng-template #postProfile>
							<img src="{{ item.parent.owner.avatar }}" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40">
						</ng-template>
					</ng-container>
					<ng-container *ngIf="item?.parent?.item_owner !== item?.parent?.user_id">
						<img src="{{ item.parent.owner.avatar }}" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40">
					</ng-container>
					<!-- <img src="{{ item.parent.owner.avatar }}" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40"> -->
				</div>
				<div class="caption d-flex align-items-start">
					<div class="handing col px-0">
						<h5 class="text-truncate">
							<ng-container *ngIf="item?.parent?.item_owner === item?.parent?.user_id">
								<a *ngIf="(moduleTag === 'UserEvent')" [routerLink]="['/events/view/', item.parent.item_id, 'timeline']"> {{ item.parent.item_name }} </a>
								<a *ngIf="(moduleTag === 'Group')" [routerLink]="['/groups/view/', item.parent.item_id, 'timeline']"> {{ item.parent.item_name }} </a>
								<a *ngIf="(moduleTag === 'Page')" [routerLink]="['/pages/view/', item.parent.item_id, 'timeline']"> {{ item.parent.item_name }} </a>
							</ng-container>
							<ng-container *ngIf="item?.parent?.item_owner !== item?.parent?.user_id">
								<a routerLink="/user/friend/timeline/{{ item.parent.owner.username }}">{{ item.parent.owner.name }} </a>
							</ng-container>
						</h5>
						<div class="time d-flex align-items-center">
							<time>{{ item.parent.created_at * 1000 | date:'MMM d, yy, h:mm a'}}</time>
						</div>
					</div>
				</div>
			</div>
			<div class="group-image" *ngIf="item.parent.medias?.length !== 0">
				<div class="d-flex autopay-videos flex-wrap img-count-{{item.parent.medias?.length}}">
					<div *ngFor="let image of item.parent.medias; let i = index" class="w-50 open-image first-video" [ngClass]="{'hide': i > 3}" (click)="openImage(image.id, item.parent.medias)">
						<ng-container *ngIf="(image?.file?.type === 'image')">
							<img src="{{image?.file?.thumb}}" alt="" class="img-fluid w-100">
						</ng-container>
						<ng-container *ngIf="(image?.file?.type === 'video')">
							<div class="videos autopay-videos">
								<i class="fas fa-video"></i>
								<video name="media" [muted]="true" controls loop preload="auto">
									<source src="{{image?.file?.videoUrl}}" type="{{image?.file?.mimetype}}">
								</video>
							</div>
						</ng-container>
						<div *ngIf="(i === 3)" class="more-images d-flex align-items-center justify-content-between">
							<span class="w-100 text-center">+ {{ item.parent.medias.length - 4 }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="item.parent.content">
				<div class="card-text">
					<read-more [text]="item.parent.content" [maxLength]="250"></read-more>
					<ng-container *ngIf="gs.checkVideoUrl(item.content)">
						<div class="post-video-priview" [innerHtml]="gs.checkVideoUrl(item.content) | safewidget: 'html'"></div>
					</ng-container>
					<ng-container *ngIf="(item.parent.linkPreview.length > 0 && !gs.checkVideoUrl(item.content))">
						<div class="link-priview d-flex flex-wrap" *ngFor="let preview of item.parent.linkPreview | slice:0:1">
							<div class="img w-100 text-center my-3 border-left border-right">
								<a href="{{preview.url}}" target="_blank">
									<img src="{{ (preview.imageUrl === 'no-avatar.png') ? '/assets/no-avatar.png' : preview.imageUrl }}" class="img-fluid" alt="{{preview.title}}"/>
								</a>
							</div>
							<div class="caption px-2 w-100">
								<h5 class="mt-0">
									<a href="{{preview.url}}" class="targer-link" target="_blank">{{preview.title}}</a>
								</h5>
								<p class="mb-0">{{preview.description}}</p>
							</div>
						</div>
					</ng-container>
					<div>
						<a routerLink="/timeline/post-view/{{ item.parent.id }}"><small>View Original Post</small></a>
					</div>
				</div>
			</div>
		</div>


		<div class="like-comment-count card-body border-top d-flex justify-content-between" *ngIf="(is_share_own_post ? (item.parent?.commentCount > 0) || (item.parent?.reactionCount > 0) || (item.shareCount > 0) : (item.parent?.commentCount > 0) || (item.reactionCount > 0) || (item.shareCount > 0))" style="margin-top: -7px;">
			<div class="count">
				<a (click)="likeUserList(is_share_own_post ? item.parent : item)" style="cursor: pointer;"><!--You and Other-->
					Likes
					<span *ngIf="(item.reactionCount > 0)">({{ is_share_own_post ? item.parent?.reactionCount : item.reactionCount }}) </span>
				</a>
			</div>
			<div class="count">
				<a routerLink="." (click)="displayCommentList()">{{ is_share_own_post ? item.parent?.commentCount : item.commentCount }} Comments</a>
			</div>
			<div class="count">
				<a>{{ is_share_own_post ? item.parent?.shareCount : item.shareCount }} ReChats</a>
			</div>
		</div>

		<div class="comment-share card-body border-top d-flex justify-content-between">
			<div class="count">
				<button type="button" class="btn btn-link" [ngClass]="item.currentReaction ? 'text-primary' : 'text-light'">
					<ng-container *ngIf="!item.currentReaction">
						<i class="fas fa-thumbs-up"></i> Like
					</ng-container>
					<span *ngIf="(item.currentReaction) && reactionsList">
						<ng-container *ngFor="let reac of reactionsList">
							<ng-container *ngIf="((reac.title | lowercase) == (item.currentReaction | lowercase))">
								{{reac.title}} <img src="{{reac.imageUrl}}" alt="" width="20" style="margin-top: -4px;">
							</ng-container>
						</ng-container>
					</span>
				</button>
				<ul *ngIf="reactionsList" class="reaction dropdown-menu">
					<li *ngFor="let reaction of reactionsList">
						<a (click)="likePost(reaction)">
							<img src="{{reaction.imageUrl}}" alt="{{reaction.title}}" title="{{reaction.title}}">
						</a>
					</li>
				</ul>
			</div>
			<div class="count">
				<button type="button" class="btn btn-link" (click)="displayCommentForm()">
					<i class="fas fa-comments"></i> Comment {{ item.commentCount }}
				</button>
			</div>
			<div class="count">
				<button type="button" class="btn btn-link" (click)="sharePost(is_share_own_post ? item.parent : item)"><i class="fas fa-sync-alt"></i> ReChat</button>
			</div>
			<div class="count">
				<div class="share" ngbDropdown>
					<button type="button" class="btn btn-link" ngbDropdownToggle><i class="fas fa-share"></i>SHARE</button>
					<div ngbDropdownMenu>
						<div ngbDropdownItem [socialShare]="'fb'" [url]="returnUrl()">Facebook</div>
						<div ngbDropdownItem [socialShare]="'tw'" [url]="returnUrl()">Twitter</div>
						<div ngbDropdownItem [socialShare]="'in'" [url]="returnUrl()">LinkedIn</div>
						<div ngbDropdownItem [socialShare]="'tr'" [url]="returnUrl()">Tumblr</div>
						<div ngbDropdownItem [socialShare]="'rd'" [url]="returnUrl()">Reddit</div>
						<div ngbDropdownItem [socialShare]="'pn'" [url]="returnUrl()">Pinterest</div>
						<div ngbDropdownItem [socialShare]="'ge'" [url]="returnUrl()">Gettr</div>
						<div ngbDropdownItem [socialShare]="'cpl'" [url]="returnUrl()">Copy Link</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <pre>{{ is_share_own_post | json }}</pre> -->

		<div class="w-100" *ngIf="showCommentForm">
			<!-- <pre>
				{{ module | json }}
			</pre> -->
			<div class="comments card-body border-top">
				<app-post-comment-form [item]="is_share_own_post ? item.parent : item" [postComments]="is_share_own_post ? item.parent.id : item.id" [module]="module" [timeline]="timeline" (updateComment)="updateCmnt($event)"></app-post-comment-form>
			</div>
		</div>
		<div class="comment-list card-body p-0" *ngIf="showCommentList">
			<app-post-comment-list [item]="is_share_own_post ? item.parent : item" [module]="module" [owner]="is_share_own_post ? item.parent.owner : item.owner" [myTimeline]="myTimeline" [timeline]="timeline"></app-post-comment-list>
		</div>

	</ng-template>
</div>



import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'



export interface Message {
	collapse_key: string,
	from: string,
	notification: {
		title: string,
		body: string
	}
}

@Injectable({
	providedIn: 'root'
})


export class PushNotoficationService {
	currentMessage = new BehaviorSubject(null);

	constructor(
		private angularFireDB: AngularFireDatabase,
		//private admin: Admin,
		private angularFireAuth: AngularFireAuth,
		private angularFireMessaging: AngularFireMessaging) {
		/*this.angularFireMessaging.onMessage.subscribe(
			(_messaging) => {
				_messaging.onMessage = _messaging.onMessage.bind(_messaging);
				_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
			}
		)*/

		//const messgr = this.angularFireMessaging.messaging();
	}

	/**
	 * update token in firebase database 
	 * 
	 * @param userId userId as a key 
	 * @param token token as a value
	 */
	updateToken(userId, token) {
		// we can change this function to request our backend service
		this.angularFireAuth.authState.pipe(take(1)).subscribe(
			() => {
				const data = {};
				data[userId] = token
				this.subscribeTopic(token);
				//this.angularFireDB.object('Covid19SurveyTopic/').update(data)
				//this.angularFireMessaging.messaging
				/*app.messaging().subscribeToTopic(token, 'Covid19SurveyTopic').then(function(response) {
						console.log('Successfully subscribed to topic:', response);
					})
					.catch(function(error) {
						console.log('Error subscribing to topic:', error);
				});*/
			})
	}

	/**
	 * request permission for notification from firebase cloud messaging
	 * 
	 * @param userId userId
	 */
	requestPermission(userId) {
		this.angularFireMessaging.requestToken.subscribe(
			(token) => {
				//console.log(token);
				this.updateToken(userId, token);
			},
			(err) => {
				console.error('Unable to get permission to notify.', err);
			}
		);
	}

	/**
	 * hook method when new notification received in foreground
	 */
	receiveMessage() {
		this.angularFireMessaging.messages.subscribe(
			(payload: Message) => {
				console.log("new message received. ", payload);
				this.currentMessage.next(payload);
				//setTimeout(function(){ this.currentMessage.next(null);; }, 3000);
			})
	}

	/**
	 * Send Server request to notification;
	 */

	removeMessage() {
		console.log("remove message received. ");
		this.currentMessage.next(null);
	}

	subscribeTopic(token = null) {
		fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/Covid19SurveyTopic`, {
		method: 'POST', // or 'PUT'
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'key=AAAACk4iqjE:APA91bEMnuevS9_QPJNA7CbqfrshPtJvAvwk5XDTa35erjN6vm-ub0P7xmc3E6bxPUswyIAR1TNXMl0iIOy4FsEP5WqQSDAG_jNyKka5OnrAyH4fFQEkVarIxBpb_A1Sa4MNORlXlyjF'
		},
		body: JSON.stringify([]),
		})
		.then(response => response.json())
		.then(data => {
			//console.log('Success:', data);
		})
		.catch((error) => {
			console.error('Error:', error);
		});
	}

	sendAction() {
		let dataList = {
			"to": "/topics/Covid19SurveyTopic",
			"data": {
				"type": "main",
				"priority": "high",
				"badge": 1,
				"message_id": 14803,
				"action": "create-post",
				"action_id": 122,
				"item_id": 4841,
				"module": "common\\models\\Post",
				"text": "Diamond Silk created the post for web. 123456",
				"textWithoutIcon": "Diamond Silk created the post. 123456",
				"recent": 1591616002,
				"title": "Diamond Silk",
				"icon": "https://uploads.chatdit.com/cache/Z/f/x/Z/I/W/I/M/ZfxZIWIMrjGxS4snDupuTedSM3CiFoIK-90x90.png",
				"link": 'https://chatdit.com/timeline/post-view/4841',
				"sender": {
					"title": "Diamond Silk",
					"icon": "https://uploads.chatdit.com/cache/Z/f/x/Z/I/W/I/M/ZfxZIWIMrjGxS4snDupuTedSM3CiFoIK-90x90.png"
				},
				data: {
					url: 'https://chatdit.com/timeline/post-view/4841'
				},
			},
			webpush: {
				fcm_options: {
					link: 'https://chatdit.com/timeline/post-view/4841'
				}
			},
			"priority": "high"
		}
		fetch(`https://fcm.googleapis.com/fcm/send`, {
		method: 'POST', // or 'PUT'
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'key=AAAACk4iqjE:APA91bEMnuevS9_QPJNA7CbqfrshPtJvAvwk5XDTa35erjN6vm-ub0P7xmc3E6bxPUswyIAR1TNXMl0iIOy4FsEP5WqQSDAG_jNyKka5OnrAyH4fFQEkVarIxBpb_A1Sa4MNORlXlyjF'
		},
		body: JSON.stringify(dataList),
		})
		.then(response => response.json())
		.then(data => {
			console.log('Success:', data);
		})
		.catch((error) => {
			console.error('Error:', error);
		});
	}
	
}


//http://www.myfuseradioonline.com/uploads/1/2/3/3/123311443/logo-maker-logo-creator-generator-designer12-12-2019-2-57-45-pm_1_orig.png
//http://www.myfuseradioonline.com/uploads/1/2/3/3/123311443/screenshot-20230215-062558-chromelogo_orig.jpg
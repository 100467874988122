import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PrivacyExpectComponent } from '../privacy-expect/privacy-expect.component';
import { PrivacySpecificComponent } from '../privacy-specific/privacy-specific.component';
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { LiveStreamComponent } from '../live-stream/live-stream.component';
import { ItemService } from 'src/app/service/item.service';
import { UserService } from 'src/app/service/user.service';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Trends } from 'src/app/models/Trends';
import { AuthSelector, DefaultSelector } from 'src/app/store/selector';
import { CommunityTimelineAction, DefaultAction, EventTimelineAction, ExploreTimelineAction, FeedTimelineAction, GroupTimelineAction, PageTimelineAction, UserTimelineAction } from 'src/app/store/action';
import * as moment from 'moment';
import { User } from 'src/app/models/User';


@Component({
	selector: 'app-create-post',
	templateUrl: './create-post.component.html',
	styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit {
	public isCollapsed2 = true;
	public isCollapsed3 = true;
	public isCollapsed4 = true;
	selected = '1';
	@Input() public updatePost: any = null;
	@Input() public addPhoto: string = null;
	@Input() public accept: any = null;

	@Input() public post_title: string = null;
	@Input() public item_id: number = null;
	@Input() public module: string = null;
	@Input() public is_owner: boolean = null;

	@Output() public photoCloseDialog = new EventEmitter();

	contents = '';
    userObservable: Observable<User> = this.store.select(AuthSelector.User);
    user: User = null;
	validForm = false;
	loading = false;
	editLoading = false;
	form: FormGroup;
	submitted = false;
	isLoading = false;
	image = [];
	imageUpdate = [];
	imageView = [];
    minDate = new Date();

	privacyExpect = [];
	privacySpecific = [];

	// Tag Friend variables
	selectable = false;
	removable = true;
	addOnBlur = true;
	friendId = [];
	filteredFruits = [];
	selectFriend: any[] = [];
	allFriend = null;
	@ViewChild('fruitInput', { static: false }) fruitInput: ElementRef<HTMLInputElement>;
	@ViewChild('posttext', { static: false }) input: ElementRef<HTMLInputElement>;
	@ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
	trendsTag: Observable<Trends[]> = this.store.select(DefaultSelector.trends);
	tagFriends = [];
	show: boolean = false;
	showLiveStream: boolean = false;

	friendExpectDialogRef: MatDialogRef<PrivacyExpectComponent>;
	friendSpecificDialogRef: MatDialogRef<PrivacySpecificComponent>;
	liveDialogRef: MatDialogRef<LiveStreamComponent>;
	constructor(
		private fb: FormBuilder,
		private commonService: CommonService,
		private userService: UserService,
		private itemService: ItemService,
		public gs: GlobalService,
		public router: Router,
		private dialog: MatDialog,
		private store: Store<{}>
	) {

	}

	get f() { return this.form.controls; }

	ngOnInit() {

		this.userObservable.subscribe(data => this.user = data);
		
		this.trendsTag.subscribe(data => {
			(data === null || data.length === 0) && this.store.dispatch(new DefaultAction.TrendsParams({ params: { key: 'trend', 'per-page': 20, page: 1 }, key: 'list'}));;
		});

		if((this.user.id === 13) || (this.user.id === 31) || (this.user.id === 14) || (this.user.id === 4667) || (this.user.id === 98)) {
			this.showLiveStream = true;
		}
		//console.log('module asdasda', this.module);
		//this.loadTrends();
		//this.loadFriends();

		this.form = this.fb.group({
			PostForm: this.fb.group({
				content: new FormControl(''),
				visibility: new FormControl(this.selected),
				location: new FormControl(''),
				tags: new FormControl([]),
				item_id: new FormControl(this.item_id),
				pinned: new FormControl('0'),
				module: new FormControl(this.module),
				publish_on: new FormControl(''),
				files: this.image,
				friends: new FormControl([]),
			})
		});

		this.form.get('PostForm').get('tags').valueChanges.pipe(debounceTime(500)).subscribe(data => {
			this.isLoading = true;
			this.userService.friends("GET", null, { q: data }).subscribe(data => {
				this.allFriend = data ? data : [];
				this.isLoading = false;
			});
		});

		this.form.get('PostForm').get('content').valueChanges.subscribe(data => {
			(data.length > 0 || this.image.length > 0) ? this.validForm = true : this.validForm = false;
		});
		this.form.get('PostForm').get('files').valueChanges.subscribe(data => {
			const content = this.form.get('PostForm').get('content');
			(data.length > 0 || content.value.length > 0) ? this.validForm = true : this.validForm = false;
		});

		if (this.updatePost) {
			if (this.updatePost.user_id !== this.user.id) {
				this.router.navigate(['/page-not-found'])
			}
			this.validForm = true;
			this.updatePost.medias.forEach(img => {
				this.image.push(img.file.id);
				this.imageView.push({ base_url: null, delete_url: null, id: img.file.id, name: null, path: null, size: null, type: img.file.mimetype, url: img.file.thumb, isAction: true, photo_id: img.id });
			});

			this.updatePost.tagFriends.forEach(tag => {
				this.friendId.push(tag.user.id);
				this.selectFriend.push(tag.user.name)
			});
			let mo = this.updatePost.module;
			let module = mo.split('\\');

			this.form.get('PostForm').patchValue({
				visibility: `${this.updatePost.visibility}`,
				item_id: this.updatePost.item_id,
				module: module.pop(),
				content: `${this.updatePost.content}`,
				location: this.updatePost.location,
				tags: this.friendId,
				publish_on: this.updatePost.publish_on,
				files: this.image
			});
			//console.log('this.form', this.form.value);
		}
	}

	loadFriends() {
		this.userService.friends("GET", null, { username: this.user.username, page: 1, 'per-apge': 100 }).subscribe((data: any) => {
			this.tagFriends = data
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	submitPost(form) {
		//form.value.PostForm.pinned = (form.value.PostForm.pinned === true) ? '1' : '0';
		const publish_on = form.value.PostForm.publish_on;
		form.value.PostForm.publish_on = moment(publish_on ? publish_on : new Date()).format('X');
		form.value.PostForm.publish_on = parseFloat(form.value.PostForm.publish_on);
		//console.log('form', form.value, form.valid);
		//return false;
		this.submitted = true;
		// if (!form.valid) {
		// 	this.submitted = false;
		// 	return;
		// }
		const params2 = (this.updatePost === null) ? null : { post_id: this.updatePost.id };
		this.itemService.post("POST", form.value, params2).subscribe((response: any) => {
			this.submitted = false;
			console.log('response', response)
			if (response.code) {
				this.gs.alert(`You have successfully ${(params2 === null) ? 'create' : 'update'} your post.`);
				let data = response.data;
				if (this.updatePost) {
					this.router.navigate(['/timeline']);
					switch (this.module) {
						case 'UserProfile':
							//this.store.dispatch(new UserTimelineAction.Update(data));
							this.store.dispatch(new FeedTimelineAction.Update(data));
							this.store.dispatch(new FeedTimelineAction.ListParams({params: { key: 'newsfeed', 'per-page': 5, page: 1 }, key: 'list'}));
							break;		
						default:
							break;
					}
				} else {
					let publish_date = form.value.PostForm.publish_on;
					let current_date = moment(new Date()).format('X');
					let module = response?.data?.module?.split('\\')?.pop();
					console.log('module', module, this.is_owner, parseInt(current_date) >= parseInt(publish_date));
					if(parseInt(current_date) >= parseInt(publish_date)) {
						console.log('module2222', module, this.is_owner);
						this.store.dispatch(new ExploreTimelineAction.Add(data));
						switch (module) {
							case 'UserProfile':
								this.store.dispatch(new UserTimelineAction.Add(data));
								this.store.dispatch(new FeedTimelineAction.Add(data));
								break;	
							case 'Page':
								if(this.is_owner) {
									this.store.dispatch(new PageTimelineAction.Add(data));
								} else {
									this.store.dispatch(new CommunityTimelineAction.Add(data));
								}
								break;	
							case 'Group':
								if(this.is_owner) {
									this.store.dispatch(new GroupTimelineAction.Add(data));
								} else {
									this.store.dispatch(new CommunityTimelineAction.Add(data));
								}
								break;
							case 'UserEvent':
								this.store.dispatch(new EventTimelineAction.Add(data));
								break;		
							default:
								break;
						}
					}
					this.photoCloseDialog.emit('closeDialog');
				}
				this.formResert();
			}
		}, (error: Response) => {
			this.gs.handleErrors(error);
			this.submitted = false;
		});
	}

	upload(files: FileList) {
		this.loading = true;
		this.validForm = false;
		if (files.length > 0) {
			const imageList = files;
			for (var i = 0; i < imageList.length; i++) {
				const imageType = imageList[i].type;
				//console.log(imageType);
				if (imageType === 'image/jpeg' || imageType === 'image/jpg' || imageType === 'image/png' || imageType === 'image/gif') {
					//console.log('if', files);
				} else {
					this.loading = false;
					const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
						width: '350px',
						data: "Please Select jpg, jpeg, png, gif file"
					});
					dialogRef.afterClosed().subscribe(result => {
						if (result) {

						}
					});
					return false;
				}
			}
			this.commonService.basicUpload(files).subscribe(event => {
				this.loading = false;
				if (event.files !== undefined && event.files.length > 0) {
					event.files.forEach(img => {
						this.image.push(img.id);
						this.imageView.push(img);
					});
					this.form.get('PostForm').patchValue({
						files: this.image,
					});
					this.validForm = true;
				}
			}, (error: Response) => {
				this.loading = false;
			});
		}

	}

	deletePhoto(photo) {
		console.log('photophoto', photo);
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "ARE YOU SURE THAT YOU WANT TO DELETE THIS PHOTO?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.media("DELETE", null, { item_id: photo.photo_id, key: 'photo' }).subscribe((data: any) => {
					this.imageView.splice(this.imageView.indexOf(photo), 1);
					this.image.splice(this.image.indexOf(photo.id), 1);
					this.form.get('PostForm').patchValue({
						files: this.image,
					});
					this.gs.alert('Your photo has been deleted successfully.');
				}, (error: Response) => {
					this.gs.handleErrors(error);
				});
			}
		});
	}

	add(event: MatChipInputEvent): void {
		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;
			// Add our fruit
			if ((value || '').trim()) {
				//this.selectFriend.push(value.trim());
			}
			// Reset the input value
			if (input) {
				input.value = '';
			}
		}
	}

	remove(fruit: string): void {
		const index = this.friendId.indexOf(fruit);
		if (index >= 0) {
			this.selectFriend.splice(index, 1);
			this.friendId.splice(index, 1);
		}
		const index2 = this.selectFriend.indexOf(fruit);
		if (index2 >= 0) {
			this.selectFriend.splice(index, 1);
			this.friendId.splice(index, 1);
		}
		this.form.get('PostForm').patchValue({
			tags: this.friendId
		});
	}

	select(event: MatAutocompleteSelectedEvent): void {
		if (this.friendId.indexOf(event.option.id) === -1) {
			this.friendId.push(event.option.id);
			this.selectFriend.push(event.option.viewValue);
		}
		//console.log(this.friendId);
		this.form.get('PostForm').patchValue({
			tags: this.friendId
		});
	}

	formResert() {
		this.isCollapsed2 = true;
		this.isCollapsed3 = true;
		this.isCollapsed4 = true;
		this.image = [];
		this.imageView = [];
		this.friendId = [];
		this.filteredFruits = [];
		this.selectFriend = [];
		this.privacyExpect = [];
		this.privacySpecific = [];
		this.form.get('PostForm').patchValue({
			visibility: this.selected,
			item_id: this.item_id,
			module: this.module,
			content: '',
			location: '',
			tags: [],
			pinned: '',
			files: [],
			friends: []
		});

	}

	onSelectPrivacy(id) {
		if (this.selected === '4' || this.selected === '5') {
			this.friendExpectDialogRef = this.dialog.open(PrivacyExpectComponent, {
				width: '550px',
				disableClose: false,
				panelClass: 'privacy-dialog',
				data: {
					friend: (this.updatePost) ? this.updatePost.privacyFriends.map(it => it.id) : this.privacyExpect,
					heading: 'Friends Except...',
					model: id
				}
			});
			this.friendExpectDialogRef.afterClosed().pipe().subscribe(data => {
				if (data == undefined) return false;
				if (data != undefined) {
					this.privacyExpect = data;
					if (data.length === 0) {
						this.form.get('PostForm').patchValue({
							visibility: `1`,
						});
					}
					this.form.get('PostForm').patchValue({
						friends: data
					});
				} else {
					this.form.get('PostForm').patchValue({
						visibility: `1`,
					});
					this.form.get('PostForm').patchValue({
						friends: []
					});
				}
			});
		}
	}

	toggle() {
		this.show = !this.show;
	}

	addEmojiPost(selected: Emoji) {
		const emoji: string = (selected.emoji as any).native;
		const input = this.input.nativeElement;
		input.focus();
		if (document.execCommand){				
			var event = new Event('input');
			document.execCommand('insertText', false, emoji);
			return; 
		}
		const [start, end] = [input.selectionStart, input.selectionEnd]; 
		input.setRangeText(emoji, start, end, 'end');
	}

	createLiveSteam(item) {
		this.liveDialogRef = this.dialog.open(LiveStreamComponent, {
			width: '250px',
			disableClose: true,
			panelClass: 'live-dialog',
			data: {
				item_id: this.item_id,
				module: this.module,
				type: item
			}
		});
	}

	clearDate(date) {
		this.form.get('PostForm').patchValue({
			publish_on: ''
		});
	}

}


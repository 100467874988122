<div class="images-box d-flex">
	<div class="image d-flex align-items-center">
			<ng-container *ngIf="photoView">
				<ng-container *ngIf="(photoView.file.type === 'image')">
					<img-lazy src="{{ photoView.file.path }}" class="img-fluid w-100"></img-lazy>
				</ng-container>
				<ng-container *ngIf="(photoView.file.type === 'video')">
					<video name="media" controls autoplay>
						<source src="{{photoView.file.videoUrl}}" type="{{photoView.file.mimetype}}">
					</video>
				</ng-container>
				<button (click)="swing(prevIndex)" *ngIf="(prevIndex)" class="btn btn-prev"><i class="fas fa-chevron-left"></i> </button>
				<button (click)="swing(nextIndex)" *ngIf="(nextIndex)" class="btn btn-next"><i class="fas fa-chevron-right"></i> </button>
				<button (click)="close()" class="btn btn-close"><i class="fas fa-times"></i></button>
			</ng-container>
	</div>
	<div class="comment">
		<div class="timeline-post card">
			<div class="card-header d-flex">
				<div class="profile">
					<img src="{{ data.owner.avatar }}" alt="" class="img-fluid rounded-circle border w-100" width="40" height="40">
				</div>
				<div class="caption d-flex align-items-start">
					<div class="handing col px-0">
						<h5 class="text-truncate">
							<a routerLink="/user/friend/timeline/{{ data.owner.username }}">{{ data.owner.name }} </a>
						</h5>
						<div class="time d-flex align-items-center">
							<time>{{ data.postDate * 1000 | date:'MMM d, yy, h:mm a'}}</time>
						</div>
					</div>
				</div>
			</div>
			<ng-container *ngIf="photoView">
				<div class="like-comment-count card-body border-bottom d-flex justify-content-between comment-share">
					<div class="count">
						<!-- <a (click)="likePhoto()" style="cursor: pointer" [ngClass]="{'text-primary': (photoView.isLike === true)}">
							Likes <span *ngIf="photoView.reactionCount > 0">({{photoView.reactionCount}})</span>
						</a> -->
						<button type="button" class="btn btn-link" [ngClass]="photoView.currentReaction ? 'text-primary' : 'text-light'">
                            <i *ngIf="!photoView.currentReaction" class="fas fa-thumbs-up"></i>
							<span *ngIf="(photoView.currentReaction) && data.reactionsList">
								<ng-container *ngFor="let reac of data.reactionsList">
									<ng-container *ngIf="((reac.title | lowercase) == (photoView.currentReaction | lowercase))">
										<img src="{{reac.imageUrl}}" alt="" width="20" style="margin-top: -4px;">
									</ng-container>
								</ng-container>
							</span>
                            Like <span *ngIf="photoView.reactionCount > 0">({{photoView.reactionCount}})</span>
						</button>
						<ul *ngIf="data.reactionsList" class="reaction dropdown-menu">
							<li *ngFor="let reaction of data.reactionsList">
								<a (click)="likePhoto(reaction)">
									<img src="{{reaction.imageUrl}}" alt="{{reaction.title}}" title="{{reaction.title}}">
								</a>
							</li>
						</ul>
					</div>
					<div class="count">
						<a>{{photoView.commentCount}} Comments</a>
					</div>
				</div>

				<div class="comment-list card-body">
					<app-post-comment-list [item]="photoView" [postId]="photoView.id" [module]="module" [isPopup]="isPopup" [owner]="data.owner" ></app-post-comment-list>
				</div>

				<app-post-comment-form [postComments]="photoView.id" [module]="module" [isPopup]="isPopup" [popupMedia]="photoView"></app-post-comment-form>

			</ng-container>
		</div>

	</div>


</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/helpers/global.service';
import { Trends } from 'src/app/models';
import { CommonService } from 'src/app/service/common.service';
import { ItemService } from 'src/app/service/item.service';
import { DefaultAction } from 'src/app/store/action';
import { DefaultSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-add-user-videos',
	templateUrl: './add-user-videos.component.html',
	styleUrls: ['./add-user-videos.component.scss']
})
export class AddUserVideosComponent implements OnInit {
	trendsTag: Observable<Trends[]> = this.gs.store.select(DefaultSelector.trends);
	form: FormGroup;
	submitted = false;
	heading = 'Upload Photos/Videos';
	privacy = "1";
	loadingVideo = false;
	loadingImage = false;
	progress: number;
	videoView = [];
	imageView = [];
	constructor(
		public gs: GlobalService,
		public commonService: CommonService,
		private itemService: ItemService,
		private dialogRef: MatDialogRef<AddUserVideosComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		console.log(this.data);
		this.form = this.gs.formBuilder.group({
			content: new FormControl(''),
			visibility: new FormControl(1),
			item_id: new FormControl(this.gs.identity.id),
			module: new FormControl('UserProfile'),
			stream_id: new FormControl(null),
			is_live: new FormControl(0),
			image_id: new FormControl(''),
			media_id: new FormControl('', [Validators.required]),
			url: new FormControl('', [Validators.required]),
			schedule: new FormControl(0),
			stream_type: new FormControl('None'),
		});
		this.trendsTag.subscribe(data => {
			(data === null || data.length === 0) && this.gs.store.dispatch(new DefaultAction.TrendsParams({ params: { key: 'trend', 'per-page': 20, page: 1 }, key: 'list'}));;
		});
	}

	submit(form: FormGroup) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return false;
		}
		//console.log('form.value', form.value);
		this.itemService.post("POST", { PostForm: form.value }).subscribe((response: any) => {
			this.submitted = false;
			if (response.code) {
				this.gs.alert(`You have successfully create your post.`);
				this.dialogRef.close(response.data);
			}
			//this.gs.alert(this.data.videoMsg ? this.data.videoMsg : data.message);
			//this.commonService.setAction(this.data.updatePage);
			//this.dialogRef.close(data.model);
		}, (error: Response) => {
			this.submitted = false;
		});
	}

	upload(files: FileList, type = 'video') {
		if(type === 'video') this.loadingVideo = true;
		if(type === 'image') this.loadingImage = true;
		this.commonService.uploadAndProgress(files).subscribe(progresData => {
			this.progress = this.commonService.calcProgressPercent(progresData);
			//console.log('progresData', progresData)
			let event = progresData.body
			if (event?.data?.files) {
				this.loadingVideo = false;
				this.loadingImage = false;
				let dd = event.data.files;
				console.log('progresData', event, dd)
				if(dd[0]?.error) {
					this.gs.alert(dd[0]?.errors, 'bg-danger')
					return
				}
				if(type === 'video') {
					this.videoView = dd[0];
					this.form.patchValue({
						media_id: dd[0].id,
						url: dd[0].path,
					});
				} 
				if(type === 'image') {
					this.imageView = dd[0];
					this.form.patchValue({
						image_id: dd[0].id
					});
				}
			}
		}, (error: Response) => {
			this.loadingVideo = false;
			this.loadingImage = false;
			this.gs.handleErrors(error);
		});
	}

	closeDialog() {
		this.dialogRef.close();
	}

}

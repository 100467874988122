<div class="header bg-primary text-white">
	<h3 mat-dialog-title class="d-flex align-items-center">
		<span class="col pl-0">Add Video</span>
		<button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
	</h3>
</div>
<mat-dialog-content class="p-0">
	<!-- <app-create-post [module]="data.module" [item_id]="data.id" [addPhoto]="'addPhoto'" (photoCloseDialog)="closeDialog()" [accept]="data.accept"></app-create-post> -->
    <div class="card new-post mb-0">
        <div class="card-body">
            <form novalidate (ngSubmit)="submit(form)" [formGroup]="form" autocomplete="off">
                <mat-form-field appearance="outline" class="comment">
                    <textarea matInput matTextareaAutosize class="link-view" placeholder="What\'s on your mind?" formControlName="content" name="posttext"  #posttext [mentionConfig]="{ mentions: [ {triggerChar:'#', maxItems:100, labelKey:'tag', items: trendsTag | async} ] }"></textarea>
                </mat-form-field>

                <div class="addPhoto">
                    <label>Select Video</label>
                    <div class="w-100 d-flex align-items-center">
                        <label class="mb-0 banner" for="customFileLangVideo">
                            <img src="/assets/img/upload-bg.jpg" alt="" class="img-fluid">
                            <input type="file" class="image" id="customFileLangVideo" accept="video/mp4,.mkv,video/x-m4v,video/*" lang="en" (change)="upload($event.target.files, 'video')" >
                        </label>
                        <div class="uploads d-flex flex-wrap mb-2">
                            <div class="images" *ngIf="videoView">
                                <video name="media" style="width: 200px !important" src="{{videoView.url}}">
                                    <source src="{{videoView.url}}" type="{{videoView.type}}">
                                </video>
                            </div>
                        </div>
                    </div>
                    <div class="images" *ngIf="loadingVideo">
                        <!-- <mat-spinner color="primary" class="spinner-size"></mat-spinner> -->
                        <ngb-progressbar *ngIf="loadingVideo" type="success" textType="white" [value]="progress" [showValue]="true" [striped]="true" [animated]="true" class="mb-3 mt-2"></ngb-progressbar>
                    </div>
                </div>

                <div class="addPhoto">
                    <label>Select Cover Image</label>
                    <div class="w-100 d-flex align-items-center">
                        <label class="mb-0 banner" for="customFileLangImage">
                            <img src="/assets/img/upload-bg.jpg" alt="" class="img-fluid">
                            <input type="file" class="image" id="customFileLangImage" accept="image/*" lang="en" (change)="upload($event.target.files, 'image')" >
                        </label>
                        <div class="uploads d-flex flex-wrap mb-2">
                            <div class="images" *ngIf="imageView">
                                <img src="{{ imageView.url }}" alt="" width="w-100" style="width: 200px !important">
                            </div>
                        </div>
                    </div>
                    <div class="images" *ngIf="loadingImage">
                        <!-- <mat-spinner color="primary" class="spinner-size"></mat-spinner> -->
                        <ngb-progressbar *ngIf="loadingVideo" type="success" textType="white" [value]="progress" [showValue]="true" [striped]="true" [animated]="true" class="mb-3 mt-2"></ngb-progressbar>
                    </div>
                </div>

                <button type="submit" class="btn btn-success btn-post" [disabled]="!form.valid" [ladda]="submitted">Add Video</button>
        
            </form>
        </div>
    </div>
    <!-- <pre>
        {{ form.value | json }}
    </pre> -->
</mat-dialog-content>


<div class="card new-post">
	<div class="card-body">
		<h5 class="card-title" *ngIf="!addPhoto">{{updatePost ? 'Update Post' : 'What\'s new ' + post_title + '?' }} </h5>

		<form novalidate (ngSubmit)="submitPost(form)" [formGroup]="form" autocomplete="off">
			<div formGroupName="PostForm">
				<div class="" style="position: relative;">
					<!-- <input type="hidden" value="{{ user.id }}" formControlName="item_id"> -->
					<mat-form-field appearance="outline" class="comment">
						<textarea matInput matTextareaAutosize class="link-view" placeholder="{{ addPhoto ? 'Say something about this photo...' : 'What\'s on your mind?' }}" formControlName="content" name="posttext"  #posttext [mentionConfig]="{ mentions: [ {triggerChar:'#', maxItems:100, labelKey:'tag', items: trendsTag | async} ] }"></textarea>
					</mat-form-field>

				</div>

				<div class="w-100 addPhoto d-flex align-items-center ">
					<div class="uploads d-flex flex-wrap mb-2" *ngIf="(imageView.length != 0) || (loading)">
						<div *ngFor="let photoView of imageView" class="images">
							<ng-container *ngIf="gs.checkMimetypeImage(photoView.type)">
								<img src="{{ photoView.url }}" alt="">
								<a class="btn btn-primary btn-sm" (click)="deletePhoto(photoView)"><i class="fas fa-trash-alt"></i></a>
							</ng-container>
							<ng-container *ngIf="gs.checkMimetypeVideo(photoView.type)">
								<video name="media" style="width: 80px !important">
									<source src="{{photoView.url}}" type="{{photoView.type}}">
								</video>
								<a class="btn btn-primary btn-sm" (click)="deletePhoto(photoView)"><i class="fas fa-trash-alt"></i></a>
							</ng-container>
						</div>
						<div class="images" *ngIf="loading">
							<mat-spinner color="primary" class="spinner-size"></mat-spinner>
						</div>
					</div>
					<label class="mb-0 banner" for="customFileLang" *ngIf="addPhoto">
						<img src="/assets/img/upload-bg.jpg" alt="" class="img-fluid">
						<input type="file" class="image" id="customFileLang" accept="{{accept}}" lang="en" multiple (change)="upload($event.target.files)" >
					</label>
				</div>

				<div class="w-100" [ngbCollapse]="isCollapsed3">
					<div class="w-100 ">
						<mat-form-field appearance="outline">
							<mat-label>Location</mat-label>
							<input matInput placeholder="Location" formControlName="location" name="location">
						</mat-form-field>
					</div>
				</div>

				<div class="w-100" [ngbCollapse]="isCollapsed2">
					<div class="w-100 ">
						<mat-form-field class="example-chip-list" appearance="outline">
							<mat-label>Tag</mat-label>
							<mat-chip-list #chipList>
								<mat-chip *ngFor="let friend of selectFriend" [selectable]="selectable" [removable]="removable" (removed)="remove(friend)">
								{{friend}}
								<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
								</mat-chip>
								<input placeholder="Tags" formControlName="tags" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
							</mat-chip-list>
							<mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
								<mat-option *ngIf="isLoading" class="is-loading">
									<mat-spinner color="primary" class="spinner-size"></mat-spinner>
								</mat-option>
								<mat-option *ngFor="let friend of allFriend" [value]="friend.name" id="{{friend.id}}">
									{{friend.name}}
								</mat-option>
								<!-- <mat-option *ngIf="!isLoading && allFriend?.length === 0">Friend not found</mat-option> -->
							</mat-autocomplete>
						</mat-form-field>
					</div>
				</div>

				<div class="w-100" [ngbCollapse]="isCollapsed4">
					<mat-form-field appearance="outline">
						<mat-label>Schedule Post Select Future date</mat-label>
						<input matInput [ngxMatDatetimePicker]="publish_on" formControlName="publish_on" [min]="minDate">
						<mat-icon *ngIf="form.value.PostForm.publish_on" matDatepickerToggleIcon style="position: absolute; right: 0px; top: 11px; cursor: pointer;" (click)="clearDate($event)">clear</mat-icon>
						<mat-datepicker-toggle matSuffix [for]="publish_on"></mat-datepicker-toggle>
						<ngx-mat-datetime-picker #publish_on [stepMinute]="10"></ngx-mat-datetime-picker>
					</mat-form-field>
				</div>

				<div class="button d-flex align-items-center flex-wrap">
					<ul class="d-flex align-items-center col px-0">
						<li *ngIf="!addPhoto">
							<label class="mb-0" for="customFileLang">
								<input type="file" class="custom-file-input" id="customFileLang" lang="en" multiple accept="image/*" (change)="upload($event.target.files)" >
								<i class="fas fa-camera mr-1"></i> Photos
							</label>
						</li>
						<li><button type="button" (click)="isCollapsed2 = !isCollapsed2"><i class="fas fa-user mr-1"></i> Tag</button></li>
						<li><button type="button" (click)="isCollapsed3 = !isCollapsed3"><i class="fas fa-map-marker-alt mr-1"></i> Location</button></li>
						<li *ngIf="showLiveStream" ngbDropdown>
							<button type="button" ngbDropdownToggle ><i class="fas fa-video mr-1"></i> Live</button>
							<div ngbDropdownMenu>
								<button type="button" ngbDropdownItem (click)="createLiveSteam('webStream')">Web Stream</button>
        						<button type="button" ngbDropdownItem (click)="createLiveSteam('appStream')">App Stream</button>
							</div>
						</li>
						<li><button type="button" (click)="isCollapsed4 = !isCollapsed4"><i class="fas fa-calendar-alt mr-1"></i> Schedule</button></li>
					</ul>

					<div class="right d-flex align-items-center">
						<!-- <mat-checkbox formControlName="pinned">Pinned</mat-checkbox> -->
						<div class="outside-click" (clickOutside)="show = false">
							<a mat-stroked-button color="primary" (click)="toggle()"><i class="far fa-smile"></i></a>
							<ng-container *ngIf="show">
								<emoji-mart (emojiClick)="addEmojiPost($event)" title="Pick your emoji"
									[showPreview]="false" [emojiTooltip]="true" set="facebook"
									[style]="{ position: 'absolute', bottom: '0px', top: 'auto', zIndex: '999' }"></emoji-mart>
							</ng-container>
						</div>
						<mat-form-field appearance="outline">
							<mat-select formControlName="visibility" name="visibility" [(value)]="selected">
								<mat-option value="1">Public</mat-option>
								<mat-option value="2">Private / Only Me</mat-option>
								<mat-option value="3">Friends</mat-option>
								<mat-option value="4" (click)="onSelectPrivacy(4)">Friends Except</mat-option>
								<mat-option value="5" (click)="onSelectPrivacy(5)">Friends Specific</mat-option>
							</mat-select>
						</mat-form-field>
						<button type="submit" class="btn btn-primary btn-post" [disabled]="!validForm" [ladda]="submitted" *ngIf="!updatePost">Post</button>
						<button type="submit" class="btn btn-success btn-post" [disabled]="!validForm" [ladda]="submitted" *ngIf="updatePost">Update</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<!--  -->